import request from '../utils/request'

export function getAnswers(data) {
    return request({
        url: '/api/answer',
        method: 'get',
        params: data
    })
}
export function postAnswers(data) {
    return request({
        url: '/api/answer',
        method: 'post',
        data
    })
}
export function putAnswers(data) {
    return request({
        url: '/api/answer',
        method: 'put',
        data
    })
}
export function deleteAnswers(data) {
    return request({
        url: '/api/answer',
        method: 'delete',
        data
    })
}