<template>
<v-container fluid class="down-top-padding">
  <v-row>
    <v-col cols="12" sm="12">
      <BaseCard heading="Жавоблар - жадвали">
        <v-data-table :headers="headers" :items="rows" :items-per-page="5" :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Қидирув" single-line hide-details></v-text-field>
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </BaseCard>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import {
  getAnswers
} from '../../api/answer'

export default {
  data() {
    return {
      search: '',
      headers: [{
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Chat id',
          value: 'chat_id'
        },
        {
          text: 'Мактаб',
          value: 'School.name'
        },
        {
          text: 'Туман',
          value: 'District.name'
        },
        {
          text: 'Шаҳар',
          value: 'City.name'
        }, 
        {
          text: 'Савол',
          value: 'Question.text'
        }, 
        {
          text: 'Жавоб',
          value: 'AnswerOption.text'
        }
      ],
      rows: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getAnswers().then(res => {
        console.log(res.data)
        this.rows = res.data
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>
